import React, {useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	Typography, Tooltip,
	Box,
	Button, IconButton,
	Menu, MenuItem,
	Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import FileUpload from './FileUpload';
import QuestionForm from './QuestionForm';

import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';

import { DefaultApi, Configuration } from 'quepasa-ai';

import {
	LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN,
	LOCAL_STORAGE_KEY_USER_INFO,
} from '../constants';

interface FileData {
	name: string;
	fileState: string; // 'loading', 'error', 'indexing', 'loaded', 'deleting'
}

interface Domain {
	name: string;
	children: Record<string, FileData>;
}

function HomePage(): JSX.Element {
	const [domains, setDomains] = useState<Record<string, Domain>>({ 'default': { name: 'default', children: {} } });
	const [closeDomains, setCloseDomains] = useState(false);
	const [quepasaAccessToken, setQuepasaAccessToken] = useState<string | null>(null);
    const [quepasa, setQuePasa] = useState<DefaultApi | null>(null);
	const [authError, setAuthError] = useState<string | null>(null);
	const [openTokenPopup, setOpenTokenPopup] = useState(false);
	const [copyTokenMessage, setCopyTokenMessage] = useState('Copy token');

	const handleDomainsUpdate = (newDomains: Record<string, Domain>) => {
		setDomains(newDomains);
	};

	const handleTokenChange = (token: string) => {
        setQuepasaAccessToken(token);
        localStorage.setItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN, token);
    };

	const navigate = useNavigate();

	useEffect(() => {
        const storedToken = localStorage.getItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN);
        if (storedToken) {
            setQuepasaAccessToken(storedToken);
        }
    }, []);

    useEffect(() => {
        if (quepasaAccessToken) {
            const apiInstance = new DefaultApi(new Configuration({ accessToken: quepasaAccessToken }));
            setQuePasa(apiInstance);
        }
    }, [quepasaAccessToken]);

    const handleInteractionWithQuestionForm = () => {
        setCloseDomains(!closeDomains);
    };

	//Settings
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = async () => {
	    try {
	        await signOut(auth);
	        console.log("User signed out successfully");
			setQuepasaAccessToken(null);
	        localStorage.removeItem(LOCAL_STORAGE_KEY_USER_INFO);
	        localStorage.removeItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN);

			setQuePasa(null);
			handleMenuClose();
			navigate('/login');
	    } catch (error) {
	        console.error("Sign out error:", error);
	    }
	};

	const handleCopyToken = async () => {
      try {
		if (quepasaAccessToken) {
        	await navigator.clipboard.writeText(quepasaAccessToken);
        	setCopyTokenMessage('Your token is copied to the clipboard');
			setTimeout(() => setCopyTokenMessage('Copy token'), 2000);
		}
      } catch (error) {
        console.error('Copy error: ', error);
      }
    };

	//
	const totalFilesCount = Object.values(domains).reduce((count, domain) => {
	    const loadedChildrenCount = Object.values(domain.children).filter(child => child.fileState === 'loaded').length;
	    return count + loadedChildrenCount;
	}, 0);

	const userInfo = localStorage.getItem(LOCAL_STORAGE_KEY_USER_INFO);
	const displayName = userInfo ? JSON.parse(userInfo)['displayName']
						: (quepasaAccessToken ? quepasaAccessToken.split(':')[0] : "");

	return (
		<>
			<div className="adaptive-flex"  style={{marginTop: "30px"}}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
					<a href="https://www.quepasa.ai" target="_blank">
		          		<img src="logo.png" alt="Logo" style={{ width: '150px', height: 'auto' }} />
					</a>
		        </Box>

				<div style={{position: "relative", display: "flex", flexDirection: "row"}}>
					{quepasaAccessToken &&
						<Typography variant="h5" component="h2" gutterBottom sx={{paddingRight: "44px"}}>
							{displayName}
						</Typography>
					}

					<IconButton
						onClick={handleSettingsClick}
						sx={{
							position: "absolute",
							top: -8,
							right: -8
						}}>
						<SettingsIcon sx={{ fontSize: 32 }}/>
					</IconButton>
					<Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
						<MenuItem onClick={handleLogout}>Log out</MenuItem>
						<MenuItem onClick={() => {setOpenTokenPopup(true)}}>My token</MenuItem>
						<Dialog open={openTokenPopup} onClose={() => {setOpenTokenPopup(false)}}>
				        <DialogTitle>
							My token

							<Box style={{position: 'absolute', right: 8, top: 8}}>
								<Tooltip
									title={copyTokenMessage}
									arrow
								>
									<IconButton
										onClick={handleCopyToken}
						            >
						            	<ContentCopyIcon />
						            </IconButton>
						        </Tooltip>

								<IconButton
								  aria-label="close"
								  onClick={() => {setOpenTokenPopup(false)}}
								>
								  <CloseIcon />
								</IconButton>
							</Box>

						</DialogTitle>
				        <DialogContent><Typography variant="body1">{quepasaAccessToken}</Typography></DialogContent>
				      </Dialog>
					</Menu>
				</div>
			</div>

			<div style={{ marginBottom: '20px' }}>
				{quepasa && <FileUpload onDomainsUpdate={handleDomainsUpdate} onAuthError={(authError) => {setAuthError(authError)}} closeDomains={closeDomains} quepasa={quepasa} />}
			</div>
			<div style={{ marginBottom: '20px' }}>
				{(quepasa && totalFilesCount > 0) &&
				<QuestionForm domains={Object.keys(domains)} questions={[]} onInteraction={handleInteractionWithQuestionForm} quepasa={quepasa} />
			}
			</div>
		</>
	);
}

export default HomePage;
