import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { auth } from '../firebaseConfig';

import {
	LOCAL_STORAGE_KEY_EMAIL_FOR_SIGN_IN,
	LOCAL_STORAGE_KEY_USER_INFO,
	LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN,
	QUEPASA_TOKENS_ENDPOINT_URL
} from '../constants';

import { getAccessToken } from '../apiService';

const FinishSignUp: React.FC = () => {
	const location = useLocation();
    const navigate = useNavigate();

	const getQuepasaAccessTokenViaApi = async (fbtoken: string): Promise<string | null> => {
	    try {
	        const data = await getAccessToken(QUEPASA_TOKENS_ENDPOINT_URL, fbtoken);
	        console.log("Access token received:", data['token']);
	        return data['token'];
	    } catch (error) {
	        console.error("Failed to get access token:", error);
	        return null;
	    }
	};

    useEffect(() => {
        const emailLink = window.location.href;
        const emailForSignIn = window.localStorage.getItem(LOCAL_STORAGE_KEY_EMAIL_FOR_SIGN_IN);

        if (isSignInWithEmailLink(auth, emailLink)) {
            completeSignIn(emailForSignIn, emailLink);
        }
    }, [location]);

    const completeSignIn = async (email: string | null, emailLink: string) => {
        if (email) {
            try {
                const result = await signInWithEmailLink(auth, email, emailLink);
                console.log('The user has successfully logged in:', result.user);
                window.localStorage.removeItem(LOCAL_STORAGE_KEY_EMAIL_FOR_SIGN_IN);

				const token = await result.user.getIdToken();
				let quepasaAccessToken = await getQuepasaAccessTokenViaApi(token);

				if (quepasaAccessToken != null) {
					localStorage.setItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN, quepasaAccessToken);

					localStorage.setItem(LOCAL_STORAGE_KEY_USER_INFO, JSON.stringify({
						uid: result.user.uid,
						fireBaseAccessToken: token,
						displayName: result.user.displayName,
						email: email
					}));

					navigate('/');
				} else {
					console.error('Wrong token received');
				}
            } catch (error) {
                console.error('Error on login completion:', error);
            }
        } else {
            console.error('Email not found in localStorage');
        }
    };

    return (
        <div>
            <h1>Loading...</h1>
        </div>
    );
};

export default FinishSignUp;
