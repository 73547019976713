import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDqxKva0kXR7lTvUcyuxbSXgEBR89ZyykY",
  authDomain: "askbot-web.firebaseapp.com",
  projectId: "askbot-web",
  storageBucket: "askbot-web.appspot.com",
  messagingSenderId: "1031614147779",
  appId: "1:1031614147779:web:9e31526be8604cbec6c676",
  measurementId: "G-640K02P0Z0"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
