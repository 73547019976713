import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { auth } from '../firebaseConfig';

import {
	LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN
} from '../constants';

interface AuthContextProps {
    currentUser: User | null;
	quepasaAccessToken: string | null;
    loading: boolean;
	setQuepasaTokenInAuthProvider: (token: string | null) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [quepasaAccessToken, setQuepasaAccessToken] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });
        return unsubscribe;
    }, []);

	// Check for stored QuePasa token
    useEffect(() => {
        const storedToken = localStorage.getItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN);
        if (storedToken) {
            setQuepasaAccessToken(storedToken);
        }
    }, []);

	const setQuepasaTokenInAuthProvider = (token: string | null) => {
        if (token) {
            localStorage.setItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN, token);
        } else {
            localStorage.removeItem(LOCAL_STORAGE_KEY_QUEPASA_ACCESS_TOKEN);
        }
        setQuepasaAccessToken(token);
    };

    return (
        <AuthContext.Provider value={{ currentUser, quepasaAccessToken, loading, setQuepasaTokenInAuthProvider }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
