import React, {useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import HomePage from './components/HomePage';
import FinBench from './components/FinBench';
import Footer from './components/Footer';
import LoginPage from './components/LoginPage';
import FinishSignUpWihEmailLink from './components/FinishSignUpWihEmailLink';
import ProtectedRoute from './components/ProtectedRoute';

import { DefaultApi, Configuration } from 'quepasa-ai';

import Intercom from '@intercom/messenger-js-sdk';

const theme = createTheme();

function App(): JSX.Element {
	useEffect(() => {
		Intercom({
			app_id: 'i4x94mrn',
		});
	}, []);

	return (
		<ThemeProvider theme={theme}>
   			<CssBaseline />
   			<Router>
   				<Container maxWidth="lg" sx={{ mt: 4 }}>
   					<Routes>
						<Route path="/login" element={<LoginPage />} />
						<Route path="/finishSignUp" element={<FinishSignUpWihEmailLink />} />
						<Route path="/" element={( <ProtectedRoute><HomePage /></ProtectedRoute> )} />
   						<Route path="/fb" element={<FinBench />} />
   					</Routes>
   				</Container>
				<Footer />
   			</Router>
	    </ThemeProvider>
	);
}

export default App;
