import React, { useEffect, useState, useRef , ChangeEvent } from 'react';
import axios from 'axios';
import {
	Button,
	Card,
	CardContent,
	Typography,
	Alert,
	Tooltip,
	IconButton,
	CircularProgress,
	LinearProgress
} from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from "@mui/icons-material/Delete";
import FileIcon from '@mui/icons-material/InsertDriveFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { DefaultApi, Configuration } from 'quepasa-ai';

import '../App.css';

import {
	LOCAL_STORAGE_KEY_FILE_UPLOADER_DATA
} from '../constants';

interface FileData {
	name: string;
	fileState: string; // 'loading', 'error', 'indexing', 'loaded', 'deleting'
}

interface Domain {
	name: string;
	children: Record<string, FileData>;
}

interface FileUploadProps {
	onDomainsUpdate: (newDomains: Record<string, Domain>) => void;
	onAuthError: (authError: string | null) => void;
	closeDomains: boolean;
	quepasa: DefaultApi;
}

const validFileFormats = ["doc", "docx", "ppt", "pptx", "xls", "xlsx", "pdf", "md", "txt", "html"];

export default function FileUpload({onDomainsUpdate, onAuthError, closeDomains, quepasa }: FileUploadProps): JSX.Element {
	const fileInputRefs = React.useRef<Record<string, HTMLInputElement | null>>({});
	const [listing, setListing] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [expandedItems, setExpandedItems] = React.useState<string[]>([]);
	const handleExpandedItemsChange = (
     	event: React.SyntheticEvent,
     	itemIds: string[],
    ) => {
		setExpandedItems(itemIds);
    };

	useEffect(() => {
		setExpandedItems([]);
	}, [closeDomains]);

	useEffect(() => {
        if (quepasa) {
            listAllFiles();
        }
    }, [quepasa]);

	const listAllFiles = async () => {
		try {
			setListing(true);
			setError(null);
			onAuthError(null);

			let results = await quepasa.listAllDocuments();

			setListing(false);

			if (results == null) {
				console.log('Failed to list files.');
				return;
			}

			if (results.length == 0) {
				const emptyDomainList = {
					'default': { name: 'default', children: {} }
				};
				saveToLocalStorage(emptyDomainList);
				setDomains(emptyDomainList)
				return;
			}

			const domainListFromApi: Record<string, Domain> = results.reduce((domainsDict: Record<string, Domain>, result) => {
			  const children: Record<string, FileData> = result.processedIds.reduce((domeinFromDict: Record<string, FileData>, fileId: string) => {
			    domeinFromDict[fileId] = {
			      name: fileId,
			      fileState: 'loaded'
			    };
			    return domeinFromDict;
			  }, {});

			  domainsDict[result.domain] = { name: result.domain, children };
			  return domainsDict;
			}, {});

			if (!('default' in domainListFromApi)) {
				domainListFromApi['default'] = { name: 'default', children: {} };
			}

			saveToLocalStorage(domainListFromApi);
			setDomains(domainListFromApi);

		} catch (err) {
			console.log('Failed to list files.');
			console.log( err );
			const authError = "Something went wrong. Check your token and enter it again!";
			setError(authError);
			onAuthError(authError);
			setListing(false);
		}
	}

	const loadFromLocalStorage = () => {
		const storedData = localStorage.getItem(LOCAL_STORAGE_KEY_FILE_UPLOADER_DATA);
		if (storedData) {
			let result = JSON.parse(storedData);
			let domainList = result.domains;
			return domainList;
		} else {
			let domainList = {
				'default': { name: 'default', children: {} } // Assuming children is a key-value structure
			};
			return domainList;
		}
	};

	const saveToLocalStorage = (domains: Record<string, Domain>) => {
		const data = {
			domains
		};
		localStorage.setItem(LOCAL_STORAGE_KEY_FILE_UPLOADER_DATA, JSON.stringify(data));
	};

	const [domains, setDomains] = useState<Record<string, Domain>>(loadFromLocalStorage());
	useEffect(() => {
	    onDomainsUpdate(domains);
	}, [domains]);


	const updateDomains = (allDomains: Record<string, Domain>) => {
		setDomains(() => {
			saveToLocalStorage(allDomains);
			onDomainsUpdate(allDomains);
			return allDomains;
		});
	};

	const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>, domainId: string) => {
		if (!event.target.files) return;

		if (!expandedItems.includes(domainId)) {
			setExpandedItems((prevExpanded) => [...prevExpanded, domainId]);
		}

		let uploadedFiles = Array.from(event.target.files).map(file => ({
			name: file.name,
			fileState: 'indexing',
			originalFile: file
		}));

		const invalidFiles = uploadedFiles.filter(file => {
			const fileExtension = file.name.toLowerCase().split('.').pop() || '';
			return !validFileFormats.includes(fileExtension);
		});
		if (invalidFiles.length > 0) {
			setError(`The following files have an unsupported type: ${invalidFiles.map(f => f.name).join(', ')}`);
			return;
		}

		uploadedFiles = uploadedFiles.filter(file => {
			const fileExtension = file.name.toLowerCase().split('.').pop() || '';
			return validFileFormats.includes(fileExtension);
		});
		if (uploadedFiles.length == 0) {
			return;
		}

		addFilesToDomain(domainId, uploadedFiles);

		for (const fileData of uploadedFiles) {
			try {
				let result = await quepasa.upsertFile({
					domain: domainId,
					file: fileData.originalFile,
				});

				updateFileStatus(domainId, fileData.name, 'loaded');
			} catch (err) {
				updateFileStatus(domainId, fileData.name, 'error');
				setError('Failed to upload files. Please try again.');
			}
		}
	};

	const updateFileStatus = (domainId: string, fileId: string, fileState: string) => {
		setDomains((prevDomains) => {
			const updatedDomain = { ...prevDomains[domainId] };
			updatedDomain.children[fileId].fileState = fileState;
			const updatedDomains = { ...prevDomains, [domainId]: updatedDomain };
			saveToLocalStorage(updatedDomains);
			return updatedDomains;
		});
	};

	const addFilesToDomain = (domain: string, newFiles: FileData[]) => {
		setDomains((prevDomains) => {
			const updatedDomain = { ...prevDomains[domain], children: { ...prevDomains[domain].children } };
			newFiles.forEach(file => updatedDomain.children[file.name] = { ...file });
			const updatedDomains = { ...prevDomains, [domain]: updatedDomain };
			saveToLocalStorage(updatedDomains);
			return updatedDomains;
		});
	};

	const deleteFileFromDomain = (domainId: string, fileId: string) => {
		setDomains((prevDomains) => {
			const updatedDomain = { ...prevDomains[domainId], children: { ...prevDomains[domainId].children } };
			delete updatedDomain.children[fileId];
			const updatedDomains = { ...prevDomains, [domainId]: updatedDomain };
			saveToLocalStorage(updatedDomains);
			return updatedDomains;
		});
	};

	const handleCreateDomain = () => {
		let newDomainName: string | null;
		// Check for valid characters: only Latin letters, numbers and _
	    const validDomainNamePattern = /^[a-zA-Z0-9_]+$/;
	    do {
			newDomainName = prompt("Enter new domain name:");
			if (newDomainName === null) return;

			if (!validDomainNamePattern.test(newDomainName)) {
		      alert('Domain name can only contain Latin letters, numbers, and "_". Please try again.');
		  } else {
			  break;
		  }
	  } while (true);

		if (domains[newDomainName]) {
		  alert("Domain name already exists.");
		  return;
		}

		if (newDomainName !== null) {
			const newDomain = { [newDomainName]: { name: newDomainName, children: {} } };
			const updatedDomains = { ...domains, ...newDomain };
			updateDomains(updatedDomains);
	    }
	};

	const handleDeleteDomain = async (domainId: string) => {
		try {
			const domain = domains[domainId];
			for (const fileId in domain.children) {
				updateFileStatus(domainId, fileId, 'deleting');
			}

	    	// Delete all files in the domain via API
	    	let result = await quepasa.removeDomain({
				domain: domainId,
			});

	    	// Update the local state by deleting the domain
			const { [domainId]: _, ...remainingDomains } = domains;
			updateDomains(remainingDomains);

	    } catch (err) {
	    	console.error('Error deleting domain:', err);
	    	setError('Failed to delete domain. Please try again.');
	    }
	};

	const handleDeleteFile = async (domainId: string, fileId: string) => {
		try {
			updateFileStatus(domainId, fileId, 'deleting');

 			// Delete a file via API
			let result = await quepasa.removeFile({
				domain: domainId,
				id: fileId,
			});

			// Update the local state
			setDomains((prevDomains) => {
				const updatedDomain = { ...prevDomains[domainId], children: { ...prevDomains[domainId].children } };
				delete updatedDomain.children[fileId];
				const updatedDomains = { ...prevDomains, [domainId]: updatedDomain };
				saveToLocalStorage(updatedDomains);
				return updatedDomains;
			});
	    } catch (err) {
	      console.error('Error deleting file:', err);
	      setError('Failed to delete file. Please try again.');
	    }
	};

	const renderTree = (nodes: Record<string, Domain>) => {
		return Object.entries(nodes).map(([domainId, value]) => (
			<TreeItem
			    key={domainId}
			    itemId={domainId}
			    label={
	      			<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
	        			<Typography variant="body2">
	          				{'Domain "' + value.name + '"'}
	        			</Typography>

						<input
							type="file"
							multiple
							style={{ display: 'none' }}
							onChange={(event) => handleFileUpload(event, domainId)} // Transfer the domainId
							ref={(el) => (fileInputRefs.current[domainId] = el)}
						/>

						<Button
						  variant="outlined"
						  size="small"
						  sx={{height: 'auto'}}
						  startIcon={<UploadFileIcon />}
						  onClick={() => fileInputRefs.current[domainId]?.click()} // Simulate clicking on an input
						>
							Upload Files
						</Button>

						{domainId != "default" &&
							<Button
								variant="outlined"
								size="small"
								 sx={{height: 'auto'}}
								startIcon={<DeleteIcon color="error" />}
								onClick={() => handleDeleteDomain(domainId)}
				            >
			          			Delete Domain
			        		</Button>
						}
			      </div>
	    		}
	  		>

				{Object.keys(value.children).length > 0 ? (
					Object.values(value.children).slice(0, 100).map((file) => (
						<TreeItem
						    key={domainId + "_" + file.name}
						    itemId={domainId + "_" + file.name}
						    label={
						      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
						        <FileIcon color="primary" />
						        <Typography variant="body2">
						        	{file.name}
									{file.fileState == 'indexing' && <span style={{ marginLeft: '10px', color: 'gray' }}>Indexing...</span>}
									{file.fileState == 'deleting' && <span style={{ marginLeft: '10px', color: 'red' }}>Deleting...</span>}
									{file.fileState == 'error' && <span style={{ marginLeft: '10px', color: 'red' }}>Error</span>}
						        </Typography>
						        <DeleteIcon color="error" onClick={() => handleDeleteFile(domainId, file.name)} />
						      </div>
						    }
						/>
					))) : (<TreeItem itemId={`${domainId}-empty`} label="Empty Domain" disabled />)
				}

				{Object.keys(value.children).length > 100 &&
					<Typography variant="body2" sx={{fontStyle: 'italic', ml: "36px"}}>...Too many files to display...</Typography>
				}
			</TreeItem>
		));
	};

 	return (
    	<Card>
      		<CardContent>
        		<div className="adaptive-flex" style={{marginBottom: '10px'}}>
          			<Typography variant="h6" component="h2" gutterBottom>
            			Upload Files
          			</Typography>

					<Button
						variant="contained"
						component="label"
						startIcon={<CreateNewFolderIcon />}
						onClick={handleCreateDomain}
						sx={{
						  width: {
							 xs: '100%', sm: 'auto', md: 'auto'
						  },
						}}
					>
            			Create New Domain
          			</Button>
        		</div>

				<Typography variant="body2">
					File formats: {validFileFormats.join(", ")}
				</Typography>

				{listing && (
					<Alert icon={<CircularProgress size={20} />} severity="info">
						Synchronizing the file list... You can continue working while synchronizing.
					</Alert>
		        )}

				{error && (
					<Alert severity="error">{error}</Alert>
				)}

				<div style={{ marginTop: '10px' }}>
					<SimpleTreeView
						expandedItems={expandedItems}
			            onExpandedItemsChange={handleExpandedItemsChange}
					>
				    	{renderTree(domains)}
					</SimpleTreeView>
				</div>

      		</CardContent>
		</Card>
	);
}
