// apiService.ts

export async function getAccessToken(endpointUrl: string, token: string): Promise<any> {
    try {
        const response = await fetch(endpointUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                provider: "firebase",
            }),
        });

        if (!response.ok) {
            throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        const data = await response.json();
        console.log(data);
        return data['data'];
    } catch (error) {
        console.error("Error fetching access token:", error);
        throw error;
    }
}
