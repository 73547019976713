import React from 'react';
import { Route, RouteProps, Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

interface ProtectedRouteProps {
    children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { currentUser, quepasaAccessToken, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }

	return currentUser || quepasaAccessToken ? (
        children
    ) : (
        <Navigate to="/login" replace />
    );
};

export default ProtectedRoute;
